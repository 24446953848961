import { Container, Grid, makeStyles, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "#120f21",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#e7b355',
    fontFamily: "AbrilFatfacefont"
  }));

export function Slideshow() {

    // const classes = useStyles();

    let messages: string[] = [
        "Avaa lahja!",
        /* "Hyvää syntymäpäivää Maiski!" */
    ];


    let [msgIndex, setMsgIndex] = useState<number>(0);

    let onClick = () => {
        setMsgIndex(++msgIndex/*  % messages.length */);
        if(msgIndex == messages.length) {

            (window as any).startSnowFlakes();
        }
    }

    let fadeEasing = {
        enter: 2000, exit: 2000
    };

    return (
        <>
            <Stack
                alignItems="center"
                style={{'background': "#120f21"}}
                >
                <Item hidden={msgIndex >= messages.length}>
                    <Item hidden={msgIndex % 2 != 0}>
                        <Fade timeout={fadeEasing}in={msgIndex % 2 == 0}>
                            <Typography align="center" variant="h2" component="h2">
                                {messages[msgIndex]}
                            </Typography>
                        </Fade>
                    </Item>
                    <Item hidden={msgIndex % 2 == 0}>
                        <Fade timeout={fadeEasing} in={msgIndex % 2 != 0}>
                            <Typography align="center" variant="h2" component="h2">
                                {messages[msgIndex]}
                            </Typography>
                        </Fade>
                    </Item>
                    <Item>
                        <Button variant="contained"
                            onClick={onClick}>
                            Klikkaa
                        </Button>
                    </Item>
                </Item>
                <Item hidden={msgIndex < messages.length}>
                    <Fade timeout={fadeEasing} in={msgIndex % 2 != 0}>
                        <div>
                        <Typography align="center" variant="h2" component="h2">
                            Hyvää syntymä-päivää Maiski!
                        </Typography>
                        <Typography align="center" variant="h4" component="h2">
                            Liput on varattu Tampereen Teatteriin 15.10.2022 klo 18:00
                        </Typography>
                             <img width={250}
                                src={`/photos/Anastasia.jpg`}
                            />
                        </div>
                    </Fade>
                </Item>
            </Stack>
        </>
    );
}