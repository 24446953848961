import React, { useEffect, useState } from "react";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import { Slideshow } from "./Slideshow";
// import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




function App() {


    return (
        <Container sx={{ mt: 5 }} maxWidth="xs">
            <Paper>
                <Slideshow/>
            </Paper>
        </Container>
    );
}

export default App;


